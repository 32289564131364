import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: "/home",
      name: "home",
      component: () => import("@/components/home.vue"),
    },
    {
      path: "/detail",
      name: "detail",
      component: () => import("@/components/detail.vue"),
    },
    {
      path: "/case",
      name: "case",
      component: () => import("@/components/case.vue"),
    },
    {
      path: "/recommend",
      name: "recommend",
      component: () => import("@/components/recommend.vue"),
    },
    {
      path: "/ours",
      name: "ours",
      component: () => import("@/components/ours.vue"),
    },
    {
      path: "/team",
      name: "team",
      component: () => import("@/components/team.vue"),
    },
    {
      path: "/",
      redirect: "/home",
    },
  ],
});
